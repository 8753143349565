<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <b-overlay :show="loading">
              <fieldset class="p-2 w-100">
                <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.trainer_evaluation')}}</legend>
                <b-col>
                  <b-table-simple borderless hover small caption-top>
                    <tbody>
                      <tr>
                        <th style="width: 22%"  class="text-left" >{{$t('elearning_tpm.course_name')}}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 75%" class="text-left">{{ $i18n.locale === 'bn' ? formData.topics_session_bn : formData.topics_session }}</td>
                      </tr>
                      <tr>
                        <th style="width: 22%" class="text-left">{{ $t('externalTraining.trainer_name') }}</th>
                        <th style="width: 3%" class="text-center" >:</th>
                        <td style="width: 75%" class="text-left" >{{ $i18n.locale === 'bn' ? formData.speaker_bn : formData.speaker }}</td>
                      </tr>
                      <tr>
                        <th style="width: 22%" class="text-left">{{ $t('elearning_tim.training_date') }}</th>
                        <th style="width: 3%" class="text-center" >:</th>
                        <td style="width: 75%" class="text-left" >{{ formData.training_date | dateFormat }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>
                </b-col>
              </fieldset>
              <br/>
              <fieldset class="p-2 w-100">
                <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.evaluation_info')}}</legend>
                <b-row>
                  <b-col>
                    <table class="table" style="margin:10px">
                      <thead class="thead">
                        <tr>
                          <th class="text-left" style="width: 10%">{{$t('globalTrans.sl_no')}}</th>
                          <th style="width: 40%" class="text-left">{{$t('externalTraining.add_trainer_evaluation')}}</th>
                          <th style="width: 25%" class="text-left">{{$t('elearning_config.title')}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(train,index) in formData.trainer_evaluation_marks" :key="index">
                          <td class="text-left">{{$n(index+1)}}</td>
                          <td class="text-left">
                            {{train.evaluation_name}}
                          </td>
                          <td>
                            <ValidationProvider :vid="'trainer_marks' + index" rules="required|min_value:1" name="Assessment">
                              <div slot-scope="{ valid, errors }">
                                <b-form-select
                                  :id="'trainer_evaluation_marks['+index+'][grading_status_id]'"
                                  v-model="train.grading_status_id"
                                  :options="train.gradingStatus"
                                  @input="gradingStatusSet(train)"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="error invalid-feedback" role="alert">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </ValidationProvider>
                          </td>
                          <td></td>
                        </tr>
                        <template v-if="formData.trainer_evaluation_marks.length === 0">
                          <tr>
                            <th colspan="3" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </fieldset>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                </div>
              </div>
            </b-overlay>
          </b-form>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerEvaluationStore, trainerEvaluationUpdate, trainerEvaluationInfo, trainerEvaluationShow } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getForm()
      this.formData.fiscal_year_id = tmp.fiscal_year_id
      this.formData.org_id = tmp.org_id
      this.formData.circular_memo_no = tmp.circular_memo_no
      this.formData.batch_no = tmp.batch_no
      this.formData.training_type_id = tmp.training_type_id
      this.formData.training_category_id = tmp.training_category_id
      this.formData.training_title_id = tmp.training_title_id
      this.formData.course_id = tmp.course_id
      this.formData.trainer_id = tmp.trainer_id
      this.formData.topics_session_bn = tmp.topics_session_bn
      this.formData.topics_session = tmp.topics_session
      this.formData.speaker = tmp.speaker_bn
      this.formData.speaker_bn = tmp.speaker_bn
      this.formData.training_date = tmp.training_date
      this.getTrainerEvaluationMarks()
    }
    if (this.$route.query.id) {
      this.getFormData()
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      circularLoading: false,
      saveBtnName: this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: '',
        fiscal_year_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        topics_session: 0,
        topics_session_bn: 0,
        speaker: 0,
        speaker_bn: 0,
        batch_no: 0,
        status: 1,
        trainer_id: 0,
        training_date: 0,
        trainer_evaluation_marks: []
      },
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainerErrors: [],
      allBatchListData: [],
      trainingCategoryList: [],
      trainingTitleList: [],
      batchList: [],
      circularList: [],
      evaluationMarks: [],
      dateList: [],
      trainerList: []
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' }
      ]
    },
    trainerEvaluationList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.trainerEvaluationList
    },
    gradingStatus: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.gradingStatus
    },
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    officeTypeList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0)
    },
    officeList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0).map(obj => {
              if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
              } else {
              return { value: obj.value, text: obj.text_en }
              }
          })
    },
    // fiscalYearList: function () {
    //   return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
    // },
    trainingTypeList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.filter(item => item.status === 1)
    },
    // trainingCategoryList: function () {
    //   return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.filter(item => item.status === 1)
    // },
    // trainingTitleList: function () {
    //   return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
    // },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
  },
  methods: {
    getForm () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async getTrainerEvaluationMarks () {
      this.loading = true
      const evaluationMarks = {
        trainer_id: this.formData.trainer_id,
        course_id: this.formData.course_id,
        circular_memo_no: this.formData.circular_memo_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerEvaluationInfo, evaluationMarks)
      if (!result.success) {
         this.formData.id = 0
        this.formData.trainer_evaluation_marks = []
        this.evaluationMarks = []
      } else {
        this.formData.id = result.data.id
        this.evaluationMarks = result.data.trainer_evaluation_marks
      }
      this.trainerEvaluationList.map(item => {
        const evaluationMarks = this.evaluationMarks.find(evaluationMark => parseInt(evaluationMark.trainer_evaluation_detail_id) === parseInt(item.value))
        const obj = {
          trainer_evaluation_detail_id: item.value,
          evaluation_name: this.currentLocale === 'en' ? item.text_en : item.text_bn,
          grading_status_id: typeof evaluationMarks !== 'undefined' ? evaluationMarks.grading_status_id : 0,
          attain_marks: typeof evaluationMarks !== 'undefined' ? evaluationMarks.attain_marks : 0,
          gradingStatus: this.gradingStatus
        }
        this.formData.trainer_evaluation_marks.push(obj)
      })
      this.loading = false
    },
    gradingStatusSet (data) {
      const gradeList = this.gradingStatus.find(gradingStatus => gradingStatus.value === data.grading_status_id)
      data.attain_marks = gradeList.average_number
      return data
    },
    async getFormData () {
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerEvaluationShow + '/' + this.$route.query.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
      }
      this.loading = false
    },
    async createData () {
      this.loading = true
      const loadinState = { loading: false, listReload: false }
      let result = null
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainerEvaluationUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerEvaluationStore, this.formData)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$bvModal.hide('modal-5')
      } else {
        if (result.errors) {
          this.trainerErrors = result.errors
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
  }
  }
}
</script>
